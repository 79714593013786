import React, { ReactElement } from 'react';
import Loader from 'react-loader-spinner';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import styled from 'styled-components';

export const LoaderContainer = styled.div`
    margin: calc(250px - 2rem) 10% 0px;
    padding-bottom: 250px;
    display: flex;
    justify-content: center;
`

export const LoaderSpinner = (): ReactElement => {
	return (
		<main role="main">
			<section className="multistep-form">
				<LoaderContainer>
					<Loader
						type="Puff"
						color="#00BFFF"
						height={100}
						width={100}
					/>
				</LoaderContainer>
			</section>
		</main>
	);
}

export default LoaderSpinner;