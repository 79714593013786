import React, { PropsWithChildren, ReactElement, useEffect, useState } from 'react';
import { NavMenu } from './NavMenu';
import { EUBanner } from './EUBanner';
import { Footer } from './Footer';
import { Content } from './Content';
import { SideNavigation } from './SideNavigation';

export const Layout = (props: PropsWithChildren<{}>): ReactElement => {
    const { children } = props;

    const [sideNavigationShown, setSideNavigationShown] = useState<boolean>(false);

    useEffect(() => {
        setSideNavigationShown(false);
    }, []);

    return (
        <>
            <div className="wrapper">
                <NavMenu setSideNavigationShown={setSideNavigationShown} />

                <Content >
                    {children}
                </Content>

                <EUBanner />
                <Footer />
            </div>

            <SideNavigation shown={sideNavigationShown} setSideNavigationShown={setSideNavigationShown} />
        </>
    );

}
Layout.DisplayName = "Layout";

