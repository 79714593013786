import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';

var applicationInsightsInstrumentationKey = process.env.APPLICATION_INSIGHTS_KEY;
if(applicationInsightsInstrumentationKey === undefined){
    applicationInsightsInstrumentationKey = '806b0fe7-0c02-4a34-984f-82769e03d9b4';
}

const browserHistory = createBrowserHistory({ basename: '' });
const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
    config: {
        instrumentationKey: applicationInsightsInstrumentationKey,
        extensions: [reactPlugin],
        extensionConfig: {
          [reactPlugin.identifier]: { history: browserHistory }
        }
    }
});
appInsights.loadAppInsights();
appInsights.trackTrace("Loaded App Insights");

export { reactPlugin, appInsights };
