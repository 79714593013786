import React, { ReactElement } from 'react';

export const SideNavigation = (props: { shown: boolean; setSideNavigationShown: React.Dispatch<React.SetStateAction<boolean>> }): ReactElement => {

    return (
        <>
            {
                props.shown &&
                <>
                    <div className="navigation-area">
                        <i className="far fa-times navigation-area__close" onClick={(e: any) => { e.preventDefault(); props.setSideNavigationShown(false); }}></i>

                        <ul className="navigation-area__nav">
                            <li>
                                <a href="/Start" title="Home">Start Assessment</a>
                            </li>
                            <li>
                                <a href="/DocumentLibrary" title="Document Library">Document Library</a>
                            </li>
                            <li>
                                <a href="/Help" title="Help">Tool User Manual</a>
                            </li>
                            <li>
                                <a href="/GdprLegislation" title="Help">GDPR Legislation</a>
                            </li>
                            <li>
                                <a href="https://idpc.org.mt/contact/" target="_blank" rel="noopener noreferrer" title="Contact Us">Contact Us</a>
                            </li>
                        </ul>
                    </div>
                    <div className="navigation-area-overlay"></div>
                </>
            }
        </>
    );
}
SideNavigation.displayName = "SideNavigation";
export default SideNavigation as React.FC;