import React, { ReactElement } from 'react';
import rightByYourSideLogo from '../img/right-by-your-side.svg';
import mobileLogo from '../img/logo-mobile.svg';
import logo from '../img/logo.svg';
import burgerLogo from '../img/burger-menu.svg';

export const NavMenu = (props: { setSideNavigationShown: React.Dispatch<React.SetStateAction<boolean>> }): ReactElement => {
	return (
		<header className="header clear" role="banner">
			<a href="/" title="Right by your Side"><img src={rightByYourSideLogo} className="header__logo-2" alt="Right by your Side"/></a>

			<a href="https://idpc.org.mt" target="_blank" rel="noopener noreferrer" title="Home">
				<img src={mobileLogo} className="header__logo header__logo--mobile" alt="Home"/>
				<img src={logo} className="header__logo header__logo--desktop" alt="Home"/>
			</a>

			<a onClick={(e: any) => { e.preventDefault(); props.setSideNavigationShown(true); }} href={"#"} className="header__nav"><img src={burgerLogo} alt="Menu"/></a>
		</header>
	);
}

NavMenu.displayName = "NavMenu";