import React, { ReactElement } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Delay from './components/UIFeatures/Delay';
import { Layout } from "./components/Layout";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "./AppInsights";
import './css/normalize.css';
import './css/style.css';
import './libs/fontawesome/css/all.min.css';

const WelcomePage = React.lazy(() => import("./components/Content/Welcome"));
const HomePage = React.lazy(() => import("./components/Content/Home"));
const TestPage = React.lazy(() => import("./components/Content/Test"));
const HelpPage = React.lazy(() => import("./components/Content/Help"));
const DocumentLibraryPage = React.lazy(() => import("./components/Content/DocumentLibrary"));
const TerminateAssessment = React.lazy(() => import("./components/Content/TerminateAssessment"))
const GdprLegislation = React.lazy(() => import("./components/Content/GdprLegislation"))

export const App = (): ReactElement => {
	return (
        <AppInsightsContext.Provider value={reactPlugin}>
            <Layout>
                <BrowserRouter basename={process.env.PUBLIC_URL}>
                    <Switch>
                        <Route
                            exact
                            path="/"
                            component={Delay(WelcomePage)}
                        />
                        <Route
                            path="/Start"
                            component={Delay(HomePage)}
                        />
                        {/*
                        USE THIS TO TEST THE REPORT FUNCTIONALITY BYPASSES THE TEST ALLTOGETHER
                        
                        <Route
                            path="/Test"
                            component={Delay(TestPage)}
                        />*/}
                        <Route
                            path="/Help"
                            component={Delay(HelpPage)}
                        />
                        <Route
                            path="/DocumentLibrary"
                            component={Delay(DocumentLibraryPage)}
                        />
                        <Route
                            path="/TerminateAssessment"
                            component={Delay(TerminateAssessment)}
                        />
                        <Route
                            path="/GdprLegislation"
                            component={Delay(GdprLegislation)}
                        />
                    </Switch>
                </BrowserRouter>
            </Layout>
        </AppInsightsContext.Provider>
	);
}
export default App;
App.displayName = "App";