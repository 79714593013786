import React, { PropsWithChildren, ReactElement } from 'react';
export const Content = (props: PropsWithChildren<{}>): ReactElement => {
    const {
        children
    } = props;

    return (
        <div>
            {children}
        </div>
    );
};
Content.displayName = "MainContent";