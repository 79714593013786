import React, { ReactElement } from 'react';
import coFinancedBanner from '../img/co-financed-banner.webp';

export const EUBanner = (): ReactElement => {
	return (
		<section className="eu-banner">
			<a href='https://ec.europa.eu/info/funding-tenders/opportunities/portal/screen/programmes/rec' target='_blank'><img src={coFinancedBanner} alt="co financed banner" /></a>
		</section>
	);
}
EUBanner.displayName = "EUBanner";