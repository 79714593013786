import React, { ReactElement } from 'react';
import iconLogo from '../img/icon-logo.svg';

export const Footer = (): ReactElement => {
    return (
        <footer className="footer">
            <p>
                <a href="https://idpc.org.mt/data-protection-policy/" target="_blank" rel="noopener noreferrer" title="Data Protection">Data Protection</a>
                |
                <a href="https://idpc.org.mt/cookies-policy/" target="_blank" rel="noopener noreferrer" title="Cookies Policy">Cookies Policy</a>
                |
                <a href="https://idpc.org.mt/accessibility-statement/" target="_blank" rel="noopener noreferrer" title="Accessibility Statement">Accessibility Statement</a>
                |
                <a href="https://idpc.org.mt/copyright/" target="_blank" rel="noopener noreferrer" title="Copyright">Copyright</a>
                |
                <a href="https://idpc.org.mt/disclaimer/" target="_blank" rel="noopener noreferrer" title="Disclaimer">Disclaimer</a>
                |
                <a href="https://idpc.org.mt/contact/" target="_blank" rel="noopener noreferrer" title="Contact Us">Contact Us</a>
            </p>
        </footer>
    );
}

Footer.displayName = "Footer";