import React, { Suspense } from 'react';
import LoaderSpinner from './LoaderSpinner';

const Delay = (Component: any) => {
    return (props: JSX.IntrinsicAttributes) => (
        <Suspense fallback={<LoaderSpinner />}>
            <Component {...props} />
        </Suspense>
    );
}

export default Delay;